'use client';
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { useRouter } from "next/navigation";
import { signOut } from 'next-auth/react';
import DynamicImage from "./ui/DynamicImage";
import { useEffect, useState } from "react";

export function Header({userRole}) {
  const router = useRouter();
  const [userLabel, setUserLabel] = useState(null);

  useEffect(() => {
    if (userRole === "Admin") {
      setUserLabel("Admin Space")
    } else {
      setUserLabel("Evaluator Space")
    }
  }, [userRole])

  const handleLogout = async () => {
    try {
      await signOut({
        callbackUrl:"/login",  // Relative URL to the login page
        redirect: true,
      });

      // Fallback to manual redirect in case signOut doesn't redirect
      router.push("/login");
    } catch (error) {
      console.error("Logout failed:", error);
      // Ensure manual redirection if something goes wrong
      router.push("/login");
    }
  };
  return (
    <header>
      <div className="h-[60px] flex items-center justify-between px-4 max-sm:px-2 bg-background border-b-2  border-[#baceed]">
        <Link href="/" className="flex items-center gap-2" prefetch={false}>
          <DynamicImage src="/botafy_logo_updated.svg" alt='logo' width={200} height={75} className="p-3 h-[75px]" />
        </Link>
        <div className="flex flex-col sm:flex-row">
          <div className="h-9 p-2.5 bg-[#e1ebff]/30 md:inline-flex max-sm:hidden rounded-full border border-[#5260eb] justify-center me-6 items-center">
            <div className="text-[#5260eb] text-base font-medium font-['Inter'] leading-none">{userLabel}</div>
          </div>
          <div onClick={handleLogout} className="text-right text-[#496188] text-xl font-medium font-['DM Sans'] underline capitalize cursor-pointer leading-normal">Log Out</div>
        </div>
      </div>
      <div className="w-full hidden h-[30px] bg-[#5260eb] max-sm:flex justify-center items-center">
        <div className="text-white text-sm font-medium font-['Inter'] leading-[14px]">Admin Space</div>
      </div>
    </header>
  );
}

